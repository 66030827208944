export function isCurrentTimeElement( element: HTMLElement ): element is CurrentTimeElement {
	return ( 'currentTime' in element ) &&
		( 'duration' in element ) &&
		( 'ended' in element ) &&
		( 'addEventListener' in element );
}

export type CurrentTimeElement = HTMLElement & {
	currentTime: number;
	duration: number;
	ended: boolean;
};
