import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { smoothScrollTo } from '../helpers/smooth-scroll';

const CSS_TRANSITION_DURATION = 2688;
const AUTO_SCROLL_DELAY = CSS_TRANSITION_DURATION * 1.25;
const SCROLL_DELAY = 1125;
const AUTO_REMOVE_DELAY = AUTO_SCROLL_DELAY + SCROLL_DELAY + 125;

defineCustomElement( 'mr-animated-logo', {
	controller: class extends BaseController {
		resolve() {
			// check if blocker did it's job
			if ( document.body.classList.contains( 'is-visited' ) ) {
				return new Promise( () => {} );
			}

			return new Promise( ( resolve ) => {
				requestAnimationFrame( () => {
					if ( 0 !== window.scrollY ) {
						window.scrollTo( 0, 0 );
					}

					resolve();
				} );
			} );
		}

		init() {
			this.elements = {};
			this.elements.animation = this.el.querySelector( '.js-animated-logo-animation' );
			this.elements.logo = this.el.querySelector( '.js-animated-logo' );
			this.elements.content = this.el.querySelector( '.js-animated-logo-content' );

			this.isAnimating = true;

			if ( this.elements.logo ) {
				setTimeout( () => {
					this.isAnimating = false;
				}, CSS_TRANSITION_DURATION );

				setTimeout( () => {
					if ( window.scrollY < this.elements.animation.offsetHeight ) {
						this.scrollToContent();
					}
				}, AUTO_SCROLL_DELAY );

				setTimeout( () => {
					requestAnimationFrame( () => {
						window.scrollTo( 0, ( window.scrollY - this.elements.animation.offsetHeight ) );
						this.elements.animation.parentNode.removeChild( this.elements.animation );
					} );

					this.el.dispatchEvent( new CustomEvent( 'mr-animated-logo:removed-animated-logo', {
						bubbles: true,
						cancelable: true,
					} ) );
				}, AUTO_REMOVE_DELAY );
			}
		}

		bind() {
			if ( this.elements.animation ) {
				this.on( 'click', ( e ) => {
					e.preventDefault();

					if ( !this.isAnimating ) {
						this.scrollToContent();
					}
				}, this.elements.animation );
			}
		}

		scrollToContent() {
			if ( this.elements.content ) {
				smoothScrollTo( 0, this.elements.animation.offsetHeight, SCROLL_DELAY );
			}
		}
	},
} );
