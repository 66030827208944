import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-accordion', {
	attributes: [],
	controller: class extends BaseController {

		init() {
			this.elements = {};
			this.elements.radio = this.el.querySelector( '.js-accordion__radio' );
			this.elements.label = this.el.querySelector( '.js-accordion__label' );

			if ( this.elements.radio.checked ) {
				this.el.classList.add( 'is-open' );
			}

			return Promise.resolve();
		}

		bind() {
			this.on( 'click', ( e ) => {
				if ( !this.elements.radio ) {
					return;
				}

				e.preventDefault();
				this.elements.radio.checked = !this.elements.radio.checked;

				window.requestAnimationFrame( () => {
					if ( this.elements.radio.checked ) {
						this.el.classList.add( 'is-open' );
					} else {
						this.el.classList.remove( 'is-open' );
					}
				} );
			}, this.elements.label );
		}
	},
} );
