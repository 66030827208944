class MrAudio extends HTMLElement {
	connectedCallback() {
		this.addEventListener( 'click', this._clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this._clickHandler );
	}

	_clickHandler( e ) {
		e.preventDefault();

		const audio = this.querySelector( '.js-audio' );
		if ( !audio ) {
			return;
		}

		if ( audio.paused ) {
			// Start audio
			this.play( audio );
		} else {
			// Stop audio
			this.pause( audio );
		}
	}

	play() {
		const audio = this.querySelector( '.js-audio' );
		if ( !audio ) {
			return;
		}

		audio.play();

		this.interval = setInterval( () => {
			this.updateTimeline();
		}, 112 );

		this.updatePlayLabel( audio.paused );
	}

	pause() {
		const audio = this.querySelector( '.js-audio' );
		if ( !audio ) {
			return;
		}

		audio.pause();

		clearInterval( this.interval );

		this.updatePlayLabel( audio.paused );
	}

	updateTimeline() {
		const audio = this.querySelector( '.js-audio' );
		const progressElement = this.querySelector( '.js-audio__progress' );
		if ( !audio || !progressElement ) {
			clearInterval( this.interval );
			this.updatePlayLabel( true );

			return;
		}

		if ( isNaN( audio.currentTime ) ) {
			return;
		}

		const progress = Number( audio.currentTime / audio.duration * 100 ).toFixed( 2 );

		Object.assign( progressElement.style, {
			width: `${progress}%`,
		} );

		if ( audio.paused ) {
			clearInterval( this.interval );
			this.updatePlayLabel( audio.paused );
		}
	}

	updatePlayLabel( paused ) {
		const playElement = this.querySelector( '.js-audio__play' );

		if ( paused ) {
			playElement.innerHTML = 'click to play';
		} else {
			playElement.innerHTML = 'click to pause';
		}
	}
}

customElements.define( 'mr-audio', MrAudio );
