import { CurrentTimeElement, isCurrentTimeElement } from '../type-guards/current-time-element';

/**
 * MrCurrentTime is a self contained `currentTime` indicator for HTMLMediaElement.
 */
export class MrCurrentTime extends HTMLElement {
	/**
	 * "data-for" is observed.
	 * You can update this attribute in DOM.
	 */
	static get observedAttributes(): Array<string> {
		return [
			'data-for',
		];
	}

	private static registeredProperties = false;

	/**
	 * When disabled, progress will not be updated.
	 *
	 */
	get disabled(): boolean {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( value: boolean ) {
		if ( value === this.disabled ) {
			return;
		}

		if ( value ) {
			this.setAttribute( 'disabled', '' );

			return;
		}

		this.removeAttribute( 'disabled' );
	}

	attributeChangedCallback( attrName: string, oldVal: string|null, newVal: string|null ): void {
		// Event listeners are added
		if ( 'data-for' === attrName ) {
			this.stopListening( oldVal );

			if ( null !== newVal ) {
				this.startListening( newVal );
			}

			return;
		}
	}

	#timeupdateHandler = (): void => {
		// when disabled do nothing
		if ( this.disabled ) {
			return;
		}

		const media = this.getAttachedMedia();
		if ( !media ) {
			return;
		}

		this.style.setProperty( '--mr-current-time--ms', this.currentTimeMs( media ) );
		this.style.setProperty( '--mr-duration--ms', this.durationMs( media ) );
	};

	connectedCallback(): void {
		if ( 'CSS' in self && 'registerProperty' in CSS ) {
			if ( MrCurrentTime.registeredProperties === false ) {
				MrCurrentTime.registeredProperties = true;

				try {
					CSS.registerProperty( {
						name: '--mr-current-time--ms',
						syntax: '<number>',
						inherits: true,
						initialValue: '0',
					} );
				} catch { }
			}
		}

		this.startListening();
	}

	disconnectedCallback(): void {
		this.stopListening();
	}

	private startListening( onId?: string|null|undefined ): void {
		requestAnimationFrame( () => {
			const media = this.getAttachedMedia( onId );
			if ( media ) {
				media.addEventListener( 'timeupdate', this.#timeupdateHandler );
				this.style.setProperty( '--mr-current-time--ms', this.currentTimeMs( media ) );
				this.style.setProperty( '--mr-duration--ms', this.durationMs( media ) );
			}
		} );
	}

	private stopListening( onId?: string|null|undefined ): void {
		const media = this.getAttachedMedia( onId );
		if ( media ) {
			media.addEventListener( 'timeupdate', this.#timeupdateHandler );
		}
	}

	private getAttachedMedia( withId?: string|null|undefined ): CurrentTimeElement | null {
		const id = withId || this.getAttribute( 'data-for' );
		if ( !id ) {
			return null;
		}

		const media = document.getElementById( id );
		if ( !media || !isCurrentTimeElement( media ) ) {
			return null;
		}

		return media;
	}

	private currentTimeMs( media: CurrentTimeElement ): string {
		if ( media.ended ) {
			return this.durationMs( media );
		}

		return this.toCSSNumber( media.currentTime );
	}

	private durationMs( media: CurrentTimeElement ): string {
		return this.toCSSNumber( media.duration );
	}

	private toCSSNumber( value: number ): string {
		if ( Number.isNaN( value ) ) {
			return 'initial';
		}


		return Math.round( value * 1000 ).toString();
	}
}
