import { defineCustomElement, BaseController, Template } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.label = this.el.getElementsByClassName( 'video__label' ).item( 0 );

			this.templates = {};
			this.templates.embed = new Template( '.tpl-video-embed', this.el );
		}

		bind() {
			const template = this.el.querySelector( '.tpl-video-embed' );

			if ( template ) {
				// Lets remove this element because IE11 plays this one automatically
				template.parentNode.removeChild( template );

				this.on( 'click', ( e ) => {
					e.preventDefault();
					this.swap();
				} );
			}
		}

		render() {
			Object.assign( this.el.style, {
				display: 'block',
				position: 'relative',
				cursor: 'pointer',
			} );
		}

		prepare() {
			if ( this.isPrepared ) {
				return;
			}

			// Wrap all existing elements in a div
			this.elements.wrap = document.createElement( 'div' );

			const blocklist = [
				'template',
			];

			const children = Array.from( this.el.childNodes, ( node ) => {
				if ( node.nodeType === node.ELEMENT_NODE ) {
					if ( blocklist.includes( node.tagName.toLowerCase() ) ) {
						return undefined;
					}
				} else if ( node.nodeType === node.COMMENT_NODE ) {
					return undefined;
				}

				return node;
			} );

			children.forEach( ( node ) => {
				if ( node ) {
					this.elements.wrap.appendChild( node );
				}

				return node;
			} );

			Object.assign( this.elements.wrap.style, {
				position: 'relative',
				'z-index': 3,
			} );

			this.el.appendChild( this.elements.wrap );

			this.isPrepared = true;
		}

		swap() {
			this.prepare();

			this.elements.embed = document.createElement( 'div' );

			this.elements.embed.appendChild( this.templates.embed.render() );

			Object.assign( this.elements.embed.style, {
				position: 'absolute',
				top: '0px',
				left: '0px',
				width: '100%',
				'z-index': 1,
			} );

			this.el.appendChild( this.elements.embed );
			this.elements.label.textContent = 'Loading video…';

			const loadable = this.elements.embed.querySelectorAll( '[src], [href]' );

			const loading = Array.from( loadable, ( node ) => {
				if ( node.complete ) {
					return Promise.resolve();
				}

				return new Promise( ( resolve ) => {
					node.addEventListener( 'load', resolve );
				} );
			} );

			this.el.classList.add( 'is-loading' );

			Promise.all( loading ).then( () => {
				requestAnimationFrame( () => {
					Object.assign( this.elements.wrap.style, {
						'pointer-events': 'none',
					} );

					this.el.classList.remove( 'is-loading' );
					this.el.classList.add( 'is-playing' );

					this.once( 'transitionend', () => {
						this.elements.wrap.parentNode.removeChild( this.elements.wrap );

						Object.assign( this.elements.embed.style, {
							position: 'static',
							top: '',
							left: '',
							width: '',
							'z-index': '',
						} );
					}, this.el );
				} );
			} );
		}
	},
} );
