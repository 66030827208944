import { isPlayPauseElement } from '../type-guards/play-pause-element';

/**
 * Create groups of media elements that must not play concurrently.
 *
 * `<video data-non-concurrent-playback-group="group1 group2">`
 */
export function manageNonConcurrentPlayback() {
	window.addEventListener( 'play', ( e: Event ) => {
		if ( !e.target ) {
			return;
		}

		const target = e.target;
		if ( !( target instanceof HTMLElement ) ) {
			return;
		}

		if ( !target.hasAttribute( 'data-non-concurrent-playback-group' ) ) {
			return;
		}

		const concurrencyGroups = ( target.getAttribute( 'data-non-concurrent-playback-group' ) || '' )
			.replace( /\s/g, ' ' )
			.split( ' ' )
			.map( ( x ) => {
				return x.trim();
			} ).filter( Boolean );
		if ( !concurrencyGroups.length ) {
			return;
		}

		concurrencyGroups.forEach( ( group ) => {
			const elements = document.querySelectorAll<HTMLElement>( `[data-non-concurrent-playback-group~="${group}"]` );
			elements.forEach( ( element ) => {
				if ( element === target ) {
					return;
				}

				if ( !isPlayPauseElement( element ) ) {
					return;
				}

				element.pause();
			} );
		} );
	}, {
		capture: true,
	} );
}
