// Easing function
const easeInOutQuart = ( time, from, distance, duration ) => {
	let phase = time / ( duration / 2 );
	if ( 1 > phase ) {
		return distance / 2 * phase * phase * phase * phase + from;
	}

	phase = phase - 2;

	return -distance / 2 * ( phase * phase * phase * phase - 2 ) + from;
};

/**
 * Smooth scroll animation
 * @param {int} endX: destination x coordinate
 * @param {int} endY: destination y coordinate
 * @param {int} duration: animation duration in ms
 */
export const smoothScrollTo = ( endX, endY, duration ) => {
	const startX = window.scrollX || window.pageXOffset;
	const startY = window.scrollY || window.pageYOffset;
	const distanceX = endX - startX;
	const distanceY = endY - startY;
	const startTime = new Date().getTime();

	const dur = duration ?? 400;

	const animate = () => {
		const elapsedTime = new Date().getTime() - startTime;
		const newX = easeInOutQuart( elapsedTime, startX, distanceX, dur );
		const newY = easeInOutQuart( elapsedTime, startY, distanceY, dur );

		window.scrollTo( newX, newY );
		if ( elapsedTime <= dur ) {
			window.requestAnimationFrame( animate );
		}
	};

	window.requestAnimationFrame( animate );
};
